import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './StaffCarousel.css'; // Custom CSS for styling

const StaffCarousel = ({ peopleList }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {peopleList.map((person, index) => (
          <div key={index} className="user-card">
            <div className="user-card__head">
                <img src={person.image} alt={person.name} className="user-image" />
                <div>
                    <h3>{person.name}</h3>
                    {
                        person?.position?<p>{person.position}</p>:<></>
                    }
                </div>
            </div>
            <p className='user-card__info'>{person.info}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default StaffCarousel;