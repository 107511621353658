import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import galleryImg1 from '../../assets/images/gallery-1.jpg'
import galleryImg2 from '../../assets/images/gallery-2.jpg'
import galleryImg3 from '../../assets/images/gallery-3.jpg'
import galleryImg4 from '../../assets/images/gallery-4.jpg'
import galleryImg5 from '../../assets/images/gallery-5.jpg'
import galleryImg6 from '../../assets/images/gallery-6.webp'
import './Gallery.css'


const Gallery = () => {
    const galleryImages = [galleryImg1, galleryImg2, galleryImg3, galleryImg4, galleryImg5, galleryImg6 ]

    useEffect(() => {
        AOS.init({
          duration: 400,
          once:false,
          easing: "ease-out-cubic",
          delay:300
        });
      }, []);

    return (
        <div className='gallery'>
            {
                galleryImages.map(item=>(
                    <img key={item} data-aos="fade-down" className='gallery-img' src={item} alt="Repair"/>
                ))
            }
        </div>
    );
};

export default Gallery;