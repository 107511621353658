import React from 'react';
import { Skeleton } from '@mui/material';
import './Skeleton.css'

const ReviewSkeleton = () => {
    return (
        <div className='skeleton'>
            <Skeleton className='skeleton-item' sx={{ bgcolor: 'grey.900' }} variant="rounded" width="45%" height="100%" />
            <Skeleton className='skeleton-item' sx={{ bgcolor: 'grey.900' }} variant="rounded" width="45%" height="100%" />
        </div>
    );
};

export default ReviewSkeleton;