import React, { useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navbar } from './components/Navbar/Navbar';
import BrandsMarquee from './components/Marquee/BrandsMarquee';
import Footer from './components/Footer/Footer';
import HomePage from './pages/Home/HomePage';
import About from './pages/About/About';
import Reviews from './pages/Reviews/Reviews';
import Service from './pages/Service/Service';
import Staff from './pages/Staff/Staff';
import Story from './pages/Story/Story';

const MainRoutes = () => {
    const footerRef = useRef(null);
    const scrollToFooter = () => {
        if (footerRef.current) {
          footerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <BrowserRouter>
            <Navbar scrollToFooter={scrollToFooter} />
            <BrandsMarquee/>
            <Routes>
                <Route exact path="/" element={<HomePage/>} />
                <Route exact path="/about" element={<About/>} />
                <Route exact path="/reviews" element={<Reviews/>} />
                <Route exact path="/service" element={<Service/>} />
                <Route exact path="/staff" element={<Staff/>} />
                <Route exact path="/story" element={<Story/>} />
            </Routes>
            <Footer ref={footerRef}/>
        </BrowserRouter>
    )
};

export default MainRoutes;