import React from 'react';
import { styled } from '@mui/material/styles';
import { Rating } from '@mui/material';
import './ReviewRating.css'

const CustomRating = styled(Rating)({
    '& .MuiRating-iconEmpty': {
      color: '#ffb400', // Custom border color for empty stars
      transform: "translateY(-15px)",
      fontSize:"49px",
      transition:'0.3s'
    },
});

const ReviewRating = ({ratingValue, readOnly, setStars, stars}) => {
    return (
        <CustomRating
            sx={{fontSize:{sm:"42px", xs:"35px"}, textAlign:'center'}}
            name="custom-rating"
            defaultValue={ratingValue}
            value={stars}
            precision={1}
            readOnly={readOnly}
            onChange={(event, newValue) => {
                setStars(newValue);
            }}
            emptyIcon={<span className='emptyStar'>&#9734;</span>}
        />
    );
};

export default ReviewRating;