import React from 'react';
import './BrandMarquee.css'
import mercedesLogo from '../../assets/icons/mercedes-benz.svg';
import audiLogo from '../../assets/icons/audi.svg'
import bmwLogo from '../../assets/icons/bmw.svg' 
import toyotaLogo from '../../assets/icons/toyota.svg'
import landRoverLogo from '../../assets/icons/land-rover.svg'
import fordLogo from '../../assets/icons/ford2.svg'

const BrandsMarquee = () => {
    const brands = [
        {
            title: 'Mercedes-Benz',
            img:mercedesLogo,
        },
        {
            title: 'Audi',
            img:audiLogo,
        },
        {
            title: 'Ford',
            img:fordLogo
        },
        {
            title: 'BMW',
            img:bmwLogo,
        },
        {
            title: 'Toyota',
            img:toyotaLogo,
        },
        {
            title: 'Land-Rover',
            img:landRoverLogo,
        },
      
    ]
    return (
        <div className="brands">
            {
                brands.map(item=>(
                    <div key={item.title} className="brand-item">
                        <img height="100%" src={item.img} alt={item.title} />
                    </div>
                ))
            }
        </div>
    );
};

export default BrandsMarquee;