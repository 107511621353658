import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import headerImg from '../../assets/images/home header background.webp'
import './Header.css'

const Header = () => {
  const navigate = useNavigate()
  return (
    <header className={`main-header`}>
      <img className='header-img' src={headerImg} alt="header background" />
      <div className="header-content">
        <h1>Next Level
        <br />Automotive Service Inc.</h1>
        <p>A place where we exceed our customers expectations</p>
        <button onClick={()=>navigate('/service')} className="header-btn">View Services</button>
      </div>
    </header>
  );
};

export default Header;