import React from 'react';
import MainRoutes from './Routes';


const App = () => {
  return (
    <>
      <MainRoutes/>
    </>
  );
};

export default App;