import React from 'react';
import './Story.css';
import stroyImg from '../../assets/images/story.webp'
import Gallery from '../../components/Gallery/Gallery';

const Story = () => {
  return (
    <div className="story">
        <h2 className="our-story-title">Our Story</h2>
        <div className="our-story-container">
          <img src={stroyImg} alt="Repair" />
          <div className="our-story-description">
            <p>It all started as a dream, loving to work with cars. Next to my garage in Philadelphia, I bought my first car that needed a transmission and knew that it would be too expensive for me to tow it to a mechanic shop and get it done, so I decided to try to swap it myself. Then, it all started: the love for solving problems on broken cars.</p>
            <p>One day in March of the year 2009, Next Level Sign went above the garage doors in Hatboro, PA. With little knowledge of how to run a company but determined to get a broken car back to life, Next Level Automotive Service Inc was born and ready to challenge anything that came in. Being in my early twenties, a lot of people/customers didn't want to take me seriously, but after a few repairs, word of mouth spread quickly. Late nights and early mornings with only a few hours of sleep, two years in, I was able to hire my first employee to do repairs while I was ordering parts, fixing cars, and greeting customers.</p>
            <p>Yes, it was not a smooth road, but with the Lord by my side, I was able to build a team of great leaders, professionals, knowledgeable, motivated, and determined to exceed our customers' expectations and do what it takes to get our customers back on the road. Whether it's fleet vehicles or customer cars, we listen to our customers' concerns and with one of the best warranties in town, we do what it takes to get the job done. Here at Next Level Auto, we offer a different variety of jobs: State Inspection, Alignments with our state-of-the-art Hunter Hawk Elite machine, Electrical Diagnosis on European, Domestic, and Asian vehicles, Preventative Maintenance, and Regular Services that are manufacturer-specific.</p>
          </div>
        </div>
        <Gallery/>
    </div>
  );
};

export default Story;
