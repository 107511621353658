import React, { useEffect, useState } from 'react';
import ReviewsCarousel from '../../components/Carousel/ReviewsCarousel.jsx';
import { fetchMessages } from '../../utils/functions.js';
import ReviewForm from '../../components/Form/ReviewForm.jsx';
import ReviewSkeleton from '../../components/Skeleton/Skeleton.jsx';
import './Reviews.css'

const Reviews = () => {
  const [reviews, setReviews] = useState([])

  const getReviews= async ()=>{
    const data = await fetchMessages()
    setReviews(data)
  }

  useEffect(() => {
    getReviews(); // Fetch messages on component mount
  }, []);

  return (
    <section className='reviews'>
      <h1>Reviews</h1>
      {
        reviews.length ? <ReviewsCarousel reviewList={reviews} /> : <ReviewSkeleton/>
      }
      <h4>Leave a feedback</h4>
      <ReviewForm setReviews={setReviews} />
    </section>
  );
};

export default Reviews;