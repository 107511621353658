import { addDoc, collection, getDocs, orderBy, query } from 'firebase/firestore';
import {db} from '../fire.js'

export function timestampToDate(timestamp) {
  const millisecondsFromSeconds = timestamp.seconds * 1000;
  const millisecondsFromNanoseconds = timestamp.nanoseconds / 1000000;
  const totalMilliseconds = millisecondsFromSeconds + millisecondsFromNanoseconds;
  const date = new Date(totalMilliseconds);
  return date.toLocaleString();
}

export async function fetchMessages(){
  try {

    const collectionRef = collection(db, 'reviews');
    const q = query(collectionRef, orderBy('date'));

    const querySnapshot = await getDocs(q);
    const reviewsArray = [];
    querySnapshot.forEach((doc) => {
      reviewsArray.push({ id: doc.id, ...doc.data() });
    });
    return reviewsArray;
  } catch (e) {
    console.error('Error getting documents: ', e);
  }
};

export async function addMessage (obj){
  try {
    await addDoc(collection(db, 'reviews'), obj);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};