import React, { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import tireImg from '../../assets/images/tires.png'
import repireImg from '../../assets/images/repair.png'
import partImg from '../../assets/images/auto-part.png'
import acImg from '../../assets/images/ac_.png'
import oilImg from '../../assets/images/oil_hange.png'
import insImg from '../../assets/images/inspection.png'
import brkImg from '../../assets/images/brakes.png'
import susImg from '../../assets/images/suspension .jpg'
import diaImg from '../../assets/images/diagnostics.png'
import engImg from '../../assets/images/eng.png'
import './Service.css'

const Service = () => {
    const works = [
        {
            title:"Preventative maintenance",
            subtitle:'Total service',
            img: tireImg
        },
        {
            title:"AC Repairs",
            subtitle:'Total service',
            img: acImg
        },
        {
            title:"Oil changes",
            subtitle:'Total service',
            img: oilImg
        },
        {
            title:"Inspection",
            subtitle:'Total service',
            img: insImg
        },
        {
            title:"Brakes changes",
            subtitle:'Total service',
            img: brkImg
        },
        {
            title:"Suspension",
            subtitle:'Total service',
            img: susImg
        },
        {
            title:"Wheel Alignment",
            subtitle:'Total service',
            img: tireImg
        },
        {
            title:"Diagnostics",
            subtitle:'Total service',
            img: diaImg
        },
        {
            title:"Engine",
            subtitle:'Service and repairs',
            img: engImg
        },
    ]

    useEffect(() => {
        AOS.init({
          duration: 400,
          once:false,
          easing: "ease-out-cubic",
        });
      }, []);
      
    return (
        <>
            <header className="service-header">
                <div className="service-header_content">
                    <div className="service-header_title">
                        <h1>What We Do</h1>
                        <p>It is a long established fact that a reader will be distracted by the</p>
                    </div>
                    <div className="works">
                        {
                            works.map(item=>(
                                <div data-aos="fade-down" key={item.title} className="works-item">
                                    <div className="work-img">
                                        <figure><img src={item.img} alt={item.title}/></figure>
                                    </div>
                                    <div className="works-item-text">
                                        <h3>{item.title}<br/><span className="blu">{item.subtitle}</span></h3>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </header>  
        </>
    );
};

export default Service;