import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { timestampToDate } from '../../utils/functions';
import './ReviewsCarousel.css'; // Custom CSS for styling
import ReviewRating from '../Rating/ReviewRating';

const ReviewsCarousel = ({ reviewList }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {reviewList.map(review => (
          <div key={review.id} className="review-card">
            <div className="review-card__head">
              <h3 className='review-card__name'>{review.name}</h3>
              <p className='review-card__date'>{timestampToDate(review.date)}</p>          
            </div>
            <div className="rating">
              <ReviewRating readOnly={true} ratingValue={+review.rating} />
            </div>
            <p className='review-card__message'>{review.feedback}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ReviewsCarousel;
