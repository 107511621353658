import React from 'react';
import inspectionLoan from '../../assets/icons/inspection_logo.png'
import oilLoan from '../../assets/icons/thr1.png'
import alignLoan from '../../assets/icons/thr2.png'
import './About.css'

const About = () => {
    const offers=[
        {
            name:"Inspection",
            img:inspectionLoan,
            description:'Inspection'
        },
        {
            name:"Oil change",
            img:oilLoan,
            description:'Oil Change'
        },
        {
            name:"Wheel Alligment",
            img:alignLoan,
            description:'Wheel Alligment'
        },
    ]
    return (
        <>
            <header className="about-header">
                <div className="about-header_content">
                    <h1>Next Level</h1>
                    <p>Welcome to Next Level, your premier destination for reliable and comprehensive automotive repair and maintenance services. With 15 years of experience in the industry, we are committed to keeping your vehicle in top condition, ensuring your safety and satisfaction on the road.</p>
                </div>
            </header>
            <main>
                <div className="offers">
                    {
                        offers.map(offer=>(
                            <div key={offer.name} className="offer-item">
                                <img src={offer.img} alt={offer.name} />
                                <p>{offer.description}</p>
                            </div>
                        ))
                    }
                </div>
            </main>
        </>
    );
};

export default About;