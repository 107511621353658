import React from 'react';
import Header from '../../components/Header/Header';

const HomePage = () => {
  return (
    <>
      <Header/>  
    </>
  );
};

export default HomePage;