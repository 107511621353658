import React, { useRef, useState } from 'react';
import { addMessage, fetchMessages } from '../../utils/functions';
import ReviewRating from '../Rating/ReviewRating';
import './ReviewForm.css'

const ReviewForm = ({setReviews}) => {
    const [stars, setStars] = useState(0)
    const formRef = useRef(null);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = formRef.current;
      const reviewObj = {
        name: form.name.value,
        feedback:form.feedback.value,
        rating:stars,
        date: new Date()
      }
      await addMessage(reviewObj)
      clearFormInputs()
      const updatedReviews = await fetchMessages()
      setReviews(updatedReviews)
    };

    const clearFormInputs = ()=>{
        const form = formRef.current;
        form.name.value=''
        form.feedback.value=''
        setStars(0)
    }

    return (
        <div className="reviews-form-section">
            <form ref={formRef} onSubmit={handleSubmit} id="request" className="main_form">
                <div className="form-row">
                    <div className="form-group">
                        <input className="contactus" placeholder="Full Name" type="text" name="name" />
                    </div>
                    <div className="form-group">
                        <ReviewRating value={stars} setStars={setStars} />
                    </div>
                    <div className="form-group">
                        <textarea className="textarea" placeholder="Message" name="feedback"></textarea>
                    </div>
                    <div className="button-group">
                        <button className="send_btn">Send</button>
                    </div>
                </div>
            </form>
      </div>
    );
};

export default ReviewForm;